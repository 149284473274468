var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-good-detail"},[_c('Breadcrumb',{attrs:{"data":[
      {
        name:'Product Detail',
      }
    ]}}),_c('div',{staticClass:"common-gap"}),_c('el-row',{staticClass:"top-content",attrs:{"gutter":30}},[_c('el-col',{staticClass:"col",attrs:{"span":12}},[_c('el-carousel',{staticClass:"cus-swiper top-card",attrs:{"trigger":"click","autoplay":_vm.autoplay,"height":_vm.getHeight,"interval":3000,"arrow":"always"}},[(_vm.productInfo.productVideo)?_c('el-carousel-item',{staticClass:"swiper-inner"},[_c('video-player',{ref:"video",staticClass:"goods-video",attrs:{"src":_vm.videoUrl},on:{"play":function($event){_vm.autoplay = false},"pause":function($event){_vm.autoplay = true}}})],1):_vm._e(),_vm._l((_vm.productInfo.productImageList),function(item){return _c('el-carousel-item',{key:item,staticClass:"swiper-inner"},[_c('el-image',{staticStyle:{"width":"100%","height":"100%","border-radius":"12px"},attrs:{"preview-src-list":_vm.productInfo.productImageList,"src":item,"fit":'contain'}})],1)})],2)],1),_c('el-col',{staticClass:"col",attrs:{"span":12}},[_c('GoodCard',{staticClass:"top-card",attrs:{"item":_vm.productInfo,"type":'detail'}})],1)],1),_c('div',{staticClass:"common-gap"}),_c('div',{staticClass:"info-content"},[_vm._m(0),_c('el-row',{staticClass:"prop-info"},_vm._l((_vm.productInfo.propertyVoList),function(item){return _c('el-col',{key:item.id,staticClass:"col",attrs:{"span":12}},[_vm._v(" "+_vm._s(item.propertyName)+": "+_vm._s(item.propertyValue)+" ")])}),1)],1),_c('div',{staticClass:"common-gap"}),_c('div',{staticClass:"info-content"},[_vm._m(1),_c('div',{staticClass:"detail-text",domProps:{"innerHTML":_vm._s(_vm.productInfo.productDetail)}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-title"},[_c('div',{staticClass:"_left"},[_vm._v(" Product properties ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-title"},[_c('div',{staticClass:"_left"},[_vm._v("Product details")])])
}]

export { render, staticRenderFns }