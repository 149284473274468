<template>
  <video
    ref="video"
    class="video-js vjs-default-skin vjs-big-play-centered"
    width="600"
    height="400"
    controls
  >
    <source :src="src"/>
  </video>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      player: null, // 用来存储当前 video
    };
  },
  mounted() { // 渲染视频
    this.player = this.$video(this.$refs.video,{},()=>{
      this.player.on("play", () => {
        this.$emit('play')
      });
      this.player.on("pause", () => {
        this.$emit('pause')
      });
    });



  },
};
</script>

<style>

/*.video-js .vjs-control-bar{*/
/*  bottom: 3rem;*/
/*}*/

</style>
