<!--商品详情-->
<template>
  <div class="page-good-detail">

    <Breadcrumb
      :data="[
        {
          name:'Product Detail',
        }
      ]"
    ></Breadcrumb>

    <div class="common-gap"></div>

    <el-row class="top-content" :gutter="30">
      <el-col class="col" :span="12">
        <el-carousel trigger="click" class="cus-swiper top-card" :autoplay="autoplay" :height="getHeight"
                     :interval="3000" arrow="always">


          <el-carousel-item class="swiper-inner" v-if="productInfo.productVideo">

            <video-player
              @play="autoplay = false"
              @pause="autoplay = true"
              class="goods-video" ref="video" :src="videoUrl"></video-player>

          </el-carousel-item>

          <el-carousel-item class="swiper-inner" v-for="item in productInfo.productImageList" :key="item">
            <el-image
              style="width: 100%; height: 100%;border-radius: 12px"
              :preview-src-list="productInfo.productImageList"
              :src="item"
              :fit="'contain'"></el-image>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col class="col" :span="12">
        <GoodCard :item="productInfo" class="top-card" :type="'detail'"></GoodCard>
      </el-col>
    </el-row>

    <div class="common-gap"></div>

    <div class="info-content">

      <div class="common-title">
        <div class="_left">
          Product properties
        </div>

      </div>

      <el-row class="prop-info">
        <el-col :key="item.id" v-for="item in productInfo.propertyVoList" class="col" :span="12">
          {{ item.propertyName }}: {{ item.propertyValue }}
        </el-col>
      </el-row>

    </div>

    <div class="common-gap"></div>

    <div class="info-content">

      <div class="common-title">
        <div class="_left">Product details</div>
      </div>

      <div class="detail-text" v-html="productInfo.productDetail">
      </div>

    </div>


  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import GoodCard from '@/components/GoodCard'
import { findProductBySPu } from '@/api/good'
import VideoPlayer from '@/components/VideoPlayer.vue';

export default {
  data: () => {
    return {
      videoUrl: '',
      autoplay: true,
      productInfo: {
        'id': 0,
        'created': [
          2023,
          3,
          5,
          10,
          29,
          52,
          909070000
        ],
        'updated': [
          2023,
          3,
          5,
          20,
          49,
          38,
          38042000
        ],
        'isDeleted': false,
        'productName': '',
        'productSpu': '',
        'productClassifyId': '',
        'tagName': null,
        'productTagId': null,
        'productSubTitle': '',
        'productUnit': '个',
        'displayOrder': '5',
        'productPrice': 0,
        'productImageId': '',
        'propertyVoList': [],
        'productDetail': '',
        'productStatus': 'N',
        'productInPromotion': 'Y',
        'productPromotionPrice': null,
        'productMinNum': 0,
        'productCycle': '',
        'productVideo': ''
      }
    }
  },
  components: { GoodCard, Breadcrumb, VideoPlayer },
  async created() {
    await this.query()
  },
  computed: {
    getHeight() {
      return '100%'
    },
    video() {
      return this.$refs.video;
    },
  },
  methods: {
    async query() {
      await this.$store.dispatch('queryCart')
      const res = await findProductBySPu(this.$route.params.id)
      // 处理数据
      this.productInfo = res.data
      if (this.productInfo.productImageId) {
        this.productInfo.productImageList = this.productInfo.productImageId.split(',')
      }
      if (this.productInfo.productVideo) {
        this.videoUrl = this.productInfo.productVideo
      }

    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>

.top-content {

  .goods-video {
    width: 100%;
    height: 100%;
  }

  padding: 40px 30px;

  .top-card {
    overflow: hidden;
    background: #F4F8FF;
    box-shadow: 5px 6px 21px 0 rgba(198, 198, 198, 0.4);
    height: 520px;
  }

}

.prop-info {
  font-size: 24px;
  font-weight: 400;
  color: #555555;
  line-height: 24px;

  .col {
    margin-top: 30px;
  }
}

.info-content {
  padding: 0 80px 30px;
}

.detail-text {
  font-size: 24px;
  line-height: 50px;
  font-weight: 400;
  color: #555555;
}

/deep/ .el-carousel__indicators--horizontal {
  bottom: 3rem;
}

/deep/ .cus-swiper {
  border-radius: 12px;
  overflow: hidden;

  .el-carousel__indicators{
    display: flex;
    width: 60%;
  }

  .el-carousel__indicator{
    flex: 1;
    padding: 20px 10px;
    //padding: 0 20px;
  }

  .el-carousel__button {
    width: 100%;
    background-color: var(--main-color-blue);
    height: 10px;
    border-radius: 6px;
  }
}
</style>
